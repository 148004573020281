import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export default function MobileSvgContainer({ children, second = false }) {

  const MobileSvgContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: second ? "100vh" : "30%",
    display: "block",
    zIndex: -1000,
    left: "0%",
    right: "0%",
    opacity: 1,
    [theme.breakpoints.up("xs")]: {
      height: "70%"
    },
    [theme.breakpoints.up("md")]: {
      opacity: 0
    }
  }))


  return <MobileSvgContainer>
    {children}
  </MobileSvgContainer>
}
