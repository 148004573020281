import {
  Routes,
  Route,
} from "react-router-dom";
import * as React from 'react';
import {ReactComponent as ChevronUp} from "./Assets/icons/chevron-up.svg"
import {Box, Fab, Zoom, createTheme} from '@mui/material';
import NavBar from "./Components/NavBar"
import useScrollTrigger from '@mui/material/useScrollTrigger';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@emotion/react';
import Home from "./Views/Home"
import Footer from "./Components/Footer";
import { LegalWarning } from "./Components/FooterComponents/LegalWarning";
import { useEffect } from "react";
import { useState } from "react";


function ScrollTop({ children, window }) {

  
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

export default function App(props) {
  const [signal, setSignal] = useState(false)
  const font = " Lato";
  const theme = createTheme({
    typography: {
      fontFamily: font,
    },
  });



  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <NavBar setSignal={setSignal} />
        {/* <Toolbar id="back-to-top-anchor" sx={{height: 0}}/> */}
        <div id="back-to-top-anchor" />
        <Box sx={{ overflowX: "hidden", width: "100%" }}>

          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>

        </Box>

        {/* Footer */}
          <Footer signal={signal} />
        {/* End footer */}
        
        <ScrollTop {...props}>
          <Fab size="medium" aria-label="scroll back to top" style={{ backgroundColor: "#00047a", color: "white"}}>
            <ChevronUp width={20} height={20} fill="white"/>
          </Fab>
        </ScrollTop>
      </React.Fragment>
    </ThemeProvider>
  );
}
