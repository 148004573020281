import { Box, Button, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import React from "react"

export default function RoundedButton({
  color,
  icon,
  margin = false,
  text = "",
  special = null,
  to = "/",
  fontSize
}) {
  const handleClick = () => {
    window.location.href = to
  }

  const RoundButton = styled(Button)(({ theme }) => ({
    backgroundColor: color,
    marginTop: margin ? "2em" : 0,
    marginBottom: margin ? "1.2em" : 0,
    borderRadius: "20px",
    color: special ? special : "white",
    minWidth: "12em",
    fill: special ? special : "white",
    "&:hover": {
      color: color,
      backgroundColor: `${color}2F`,
      border: `.5px solid ${color}`,
      fill: color
    },
    [theme.breakpoints.up("xl")]: {
      transform: "scale(1.2) translateX(2px)"
      // transform: "translateX(2px)"
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "10em"
    }
  }))

  return (
    <RoundButton
      elevation={2}
      variant="contained"
      size="large"
      onClick={handleClick}
    >
      <Typography fontSize={fontSize}>{text}</Typography> {icon}
    </RoundButton>
  )
}

export const ButtonWrapper = ({ children }) => {
  const Wrapper = styled(Box)(({ theme }) => ({
    minHeight: "4em",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "strech",
    [theme.breakpoints.down("sm")]: {
      minHeight: "5em",
      paddingBottom: ".7em"
    }
  }))

  return <Wrapper>{children}</Wrapper>
}
