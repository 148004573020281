import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Separator from '../Separator';
// import { ReactComponent as SvgHero } from "../../Assets/Images/main-hero.svg"
// import SvgHero from "../../Assets/Images/main-hero.svg"
// import { ReactComponent as SvgHeroBg } from "../../Assets/Images/main-hero.svg"
import hero from "../../Assets/Images/main-hero-png.png"
import { ReactComponent as ChevronRight } from "../../Assets/icons/chevron-right.svg"
import RoundedButton, { ButtonWrapper } from '../RoundedButton';
import MobileSvgContainer from '../MobileSvgContainer';
import { Fade, Zoom } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';


export default function MainBanner() {


  const BannerBox = styled(Box)(({ theme }) => ({
    width: "100%",
    background: "linear-gradient(0deg, rgba(70,140,202,0.2343312324929971) 0%, rgba(70,140,202,0.03) 100%)",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      background: "linear-gradient(0deg, rgb(114 162 205) 0%, rgb(255 255 255 / 60%) 100%)",
      height: "91vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "90vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "85vh",
    }
  }));

  const LeftContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    // backgroundColor: "transparent",
    padding: "3%",
    paddingLeft: "8%",
    minWidth: "349.406px",
    flex: 2,
    flexDirection: "column",
    fontSize: "1.4rem",
    textAlign: "justify",
    alignItems: "flex-start",
    height: "100%",
    justifyContent: "space-evenly",
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
      fontSize: "1rem",
      alignItems: "center",
      padding: "5.6%",
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: "1.3rem",
      padding: "5.6%",
      paddingRight: "10%",
    },
    [theme.breakpoints.up('md')]: {
      fontSize: "1rem",
      padding: "8%"
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "1.2rem",
      padding: "5.6%"
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: "1.6rem",
      paddingLeft: "10%",
    },
    color: "#4D4D4D"
  }));

  const RightContainers = styled(Box)(({ theme }) => ({
    display: "flex",
    backgroundColor: "transparent",
    paddingTop: ".5%",
    paddingBottom: "1.5%",
    flex: 1.3,
    paddingRight: "8%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      paddingRight: "10%",
    }
  }));

  const TitleStyles = styled(Typography)(({ theme }) => ({
    fontSize: '2rem',
    [theme.breakpoints.up('md')]: {
      textAlign: "left",
      fontSize: '2.7rem',
    },
    [theme.breakpoints.up('xl')]: {
      // textAlign: "center",
      fontSize: "3.8rem",
      // alignItems: "center",
    },
    color: "#00047a",
    fontWeight: "800",
    WebkitFontSmoothing: "auto",
    lineHeight: "1.25em",
  }))

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      if(window.cookieconsent) {
        window.cookieconsent.initialise({
          cookie: {
            domain: window.location.hostname,
            name: "cookie_consent",
          },
          palette: {
            popup: { background: '#F7FAFD' },
            button: { background: '#6B78B8' },
          },
          content: {
            message: t('CookieContent'),
          },
        })
      }
    }, 2000)
  }, [t])

  return (
    <BannerBox >
      <MobileSvgContainer>
        {/* <SvgHeroBg width="100%" height="100%"/> */}
        <img width={"90%"} height={"100%"} src={hero} loading="lazy" alt='main-hero'/>
      </MobileSvgContainer>

      <LeftContainer>
        <Zoom triggerOnce cascade duration={500}>
          <Box>
            <TitleStyles>
            {t('GENERADOR ONLINE DE')}
            </TitleStyles>
            <TitleStyles>
              <span style={{ WebkitTextStroke: "1px #6b78b8", color: "transparent" }}>{t('COLECCIONES')}</span> {t('NFTs')}
            </TitleStyles>
          </Box>

          <Separator color="#6b78b8" />

          <Box>
            {t('seccion 1 texto 1')}
            <Separator special=".7em" />
            <strong>{t('seccion 1 texto 2')}</strong>
          </Box>

          <ButtonWrapper>
            <RoundedButton color="#00047a" icon={<ChevronRight height={15} width={20} />} text={t("Genera tu Colección NFT")} align='flex-start' to="https://nftgen.devtop.online/" />
          </ButtonWrapper>

        </Zoom>
      </LeftContainer>

      <RightContainers>
        <Fade style={{ width: "100%", height: "100%" }} triggerOnce={true}>
          <img width={"100%"} height={"100%"} src={hero} loading="lazy" alt='main-hero'/>
        </Fade>
      </RightContainers>
    </BannerBox>
  );
}
