import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * Componente TextField personalizado con estilos adicionales.
 * @param {Object} props - Propiedades del componente.
 * @returns {JSX.Element} - Elemento JSX que representa el componente.
 */

const CustomTextField = styled(TextField)({
  //cuando el input tenga el foco
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
  },
  //color inicialdel borde del input (sin el foco)
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ffffff',
    },
    '&:hover fieldset': {
      borderColor: '#ffffff',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff',
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
  },


});

  
 export default CustomTextField;