import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { CustomModal } from "./CustomModal";
import { useTranslation } from "react-i18next";
import Separator from "../Separator";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";

const TitleStyles = styled(Typography)(({ theme }) => ({
  fontSize: ".8rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "4rem",
  },
  color: "#6b78b8",
  fontWeight: "800",
  WebkitFontSmoothing: "auto",
}));

export const LegalWarning = ({ open, handleClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomModal open={open} handleClose={handleClose}>
        <Stack flexDirection={"row"} justifyContent={"center"}>
          <Box width={"95%"}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h4"
              sx={{ textAlign: "center", mt: 2 }}
            >
              <TitleStyles>{t("Aviso Legal")}</TitleStyles>
            </Typography>
          </Box>
          {/* <Box width={"3%"} mt={1} justifyContent={"flex-start"}>
            <Close
              onClick={handleClose}
              sx={{ cursor: "pointer", width: "18px", height: "18px" }}
            />
          </Box> */}
        </Stack>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "65vh",
            px: 3,
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <Typography
            textAlign={"center"}
            variant="h5"
            id="modal-modal-description"
          >
            {t("aviso.legal.item1.title")}
          </Typography>
          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item1.p1")}
            </Typography>

            <br />
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item1.p2")}
            </Typography>
            <br />

            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item1.p3")}
            </Typography>
          </Box>
          <Typography
            my={2}
            textAlign={"center"}
            variant="h5"
            id="modal-modal-description"
          >
            {t("aviso.legal.item2.title")}
          </Typography>

          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item2.p1")}
            </Typography>

            <br />
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item2.p2")}
            </Typography>
            <br />

            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item2.p3")}
            </Typography>
          </Box>

          <Typography
            my={2}
            textAlign={"center"}
            variant="h5"
            id="modal-modal-description"
          >
            {t("aviso.legal.item3.title")}
          </Typography>
          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item3.p1")}
            </Typography>
          </Box>
          <Typography
            my={2}
            textAlign={"center"}
            variant="h5"
            id="modal-modal-description"
          >
            {t("aviso.legal.item4.title")}
          </Typography>

          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item4.p1")}
            </Typography>

            <br />
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item4.p2")}
            </Typography>
            <br />

            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item4.p3")}
            </Typography>
          </Box>
          <Typography
            my={2}
            textAlign={"center"}
            variant="h5"
            id="modal-modal-description"
          >
            {t("aviso.legal.item5.title")}
          </Typography>
          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item5.p1")}
            </Typography>

            <br />
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item5.p2")}
            </Typography>
            <br />

            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item5.p3")}
            </Typography>
          </Box>

          <Typography
            my={2}
            textAlign={"center"}
            variant="h5"
            id="modal-modal-description"
          >
            {t("aviso.legal.item6.title")}
          </Typography>

          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item6.p1")}
            </Typography>

            <br />
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("aviso.legal.item6.p2")}
            </Typography>
            <br />
          </Box>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
          <Button
            onClick={handleClose}
            sx={{
              justifyContent: "center",
              display: "flex",
              textAlign: "center",
              color: "white",
              backgroundColor: "#6b78b8",
              borderRadius: "15px",
              "&:hover": {
                backgroundColor: "white",
                color: "#6b78b8",
                border: "1px solid #6b78b8",
              },
            }}
          >
            {t("Aceptar")}
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};
