import React from 'react'
import { Box, Modal } from "@mui/material";

export const CustomModal = ({open, handleClose, children}) => {
    const handlePreventCloseOnBackdrop = (e) => {
        const reason = 'backdropClick'
        reason === 'backdropClick' ? e.stopPropagation() : handleClose()
    }

  const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      backgroundColor: 'white',
      borderRadius: '15px',
      maxHeight: '90%',
    };

  return (
    <div>
      <Modal
        open={open}
        onClose={handlePreventCloseOnBackdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box sx={style}>
          {children}
        </Box>
      </Modal>
    </div>
  );
}
