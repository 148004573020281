import { Box, Button, Stack, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomModal } from "./CustomModal";
import { Close } from "@mui/icons-material";

const TitleStyles = styled(Typography)(({ theme }) => ({
  fontSize: "1.7rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "2rem",
  },
  color: "#6b78b8",
  fontWeight: "800",
  WebkitFontSmoothing: "auto",
}));

export const TermsNCondition = ({ open, handleClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomModal open={open} handleClose={handleClose}>
        <Stack flexDirection={"row"} justifyContent={"center"}>
          <Box width={"96%"}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center", mt: 2, width: "99%" }}
            >
              <TitleStyles>{t("legalwarning.termsncondition")}</TitleStyles>
            </Typography>
          </Box>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "65vh",
            px: 3,
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <Typography
            mt={2}
            textAlign={"initial"}
            variant="h6"
            id="modal-modal-description"
          >
            {t("legalwarning.date")}
          </Typography>
          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <Typography
              width={"100%"}
              fontSize={17}
              variant="h6"
              textAlign={"start"}
            >
              <ins>{t("legalwarning.aceptTitle")}</ins>
            </Typography>

            <br />
            <Typography
              width={"100%"}
              fontSize={17}
              textAlign={"start"}
              variant="h5"
            >
              {t("legalwarning.aceptText")}
            </Typography>
            <br />
          </Box>
          <Typography
            my={2}
            fontSize={17}
            variant="h6"
            fontWeight={"bold"}
            id="modal-modal-description"
          >
            <ins>{t("legalwarning.firstTitle")}</ins>
          </Typography>

          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("legalwarning.1.1")}
            </Typography>

            <br />
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("legalwarning.1.2")}
            </Typography>
            <br />

            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("legalwarnig.1.3")}
            </Typography>
          </Box>

          <Typography
            my={2}
            fontSize={17}
            fontWeight={"bold"}
            variant="h6"
            id="modal-modal-description"
          >
            <ins>{t("legalwarning.secondTitle")}</ins>
          </Typography>
          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("legalwarning.2.1")}
            </Typography>
            <br />
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("legalwarning.2.2")}
            </Typography>
          </Box>
          <Typography
            my={2}
            fontSize={17}
            variant="h6"
            fontWeight={"bold"}
            id="modal-modal-description"
          >
            <ins>{t("legalwarning.thirdTitle")}</ins>
          </Typography>

          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("legalwarning.3.1")}
            </Typography>
          </Box>
          <Typography
            my={2}
            fontSize={17}
            variant="h6"
            fontWeight={"bold"}
            id="modal-modal-description"
          >
            <ins>{t("legalwarning.fourthTitle")}</ins>
          </Typography>

          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("legalwarning.4.1")}
            </Typography>

            <br />
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("legalwarning.4.2")}
            </Typography>
            <br />
          </Box>

          <Typography
            my={2}
            fontSize={17}
            variant="h6"
            fontWeight={"bold"}
            id="modal-modal-description"
          >
            <ins>{t("legalwarning.fiveTitle")}</ins>
          </Typography>

          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("legalwarning.5")}
            </Typography>
          </Box>
          <Typography
            my={2}
            fontSize={17}
            variant="h6"
            fontWeight={"bold"}
            id="modal-modal-description"
          >
            <ins>{t("legalwarning.sixTitle")}</ins>
          </Typography>
          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("legalwarning.6")}
            </Typography>
          </Box>
          <Typography
            my={2}
            fontSize={17}
            variant="h6"
            fontWeight={"bold"}
            id="modal-modal-description"
          >
            <ins>{t("legalwarning.sevenTitle")}</ins>
          </Typography>

          <Box
            sx={{
              width: "100%", // Asegurarse de que el Box ocupe el 100% del ancho disponible
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography width={"100%"} fontSize={17} textAlign={"start"}>
              {t("legalwarning.7")}
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
          <Button
            onClick={handleClose}
            sx={{
              justifyContent: "center",
              display: "flex",
              textAlign: "center",
              color: "white",
              backgroundColor: "#6b78b8",
              borderRadius: "15px",
              "&:hover": {
                backgroundColor: "white",
                color: "#6b78b8",
                border: "1px solid #6b78b8",
              },
            }}
          >
            {t("Aceptar")}
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};
