import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import RoundedButton from "./RoundedButton";
import Logo from "../Assets/Images/logo.png";
import { Switch } from "@mui/material";
import es from "../Assets/icons/espana.png";
import en from "../Assets/icons/reino-unido.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const pages = [
  "¿Qué es un generador nft?",
  "Caracteristicas",
  "Lista de precios",
];
const handleClick = (event, name) => {
  const fname = event.target.name || name;
  const anchor = document.getElementById(fname);

  if (anchor) {
    anchor.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

const NavBar = ({setSignal}) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (event, name) => {
    setAnchorElNav(null);
    setTimeout(() => handleClick(event, name), 100);
  };

  const [idioma, setIdioma] = useState("en");

  const { t, i18n } = useTranslation();


  const handleMangoAwao = (e) => {
    setSignal((prevState) => !prevState)
    if (idioma === "en") {
      i18n.changeLanguage("es");
      setIdioma("es");
    } else {
      i18n.changeLanguage("en");
      setIdioma("en");
    }
  }



  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "white",
        color: "black",
        height: { xs: "9vh", md: "10vh", lg: "15vh" },
      }}
      elevation={0}
    >
      <Container
        maxWidth="xl"
        sx={{
          paddingLeft: { md: "3.2em", lg: "4.6em", xl: "0em" },
          paddingRight: { md: "3.2em", lg: "4.6em", xl: "0em" },
          height: { xs: "9vh", md: "10vh", lg: "15vh" },
        }}
      >
        <Toolbar
          disableGutters
          sx={{ height: { xs: "9vh", md: "10vh", lg: "15vh" } }}
        >
          <Link to="/">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
            >
              <img src={Logo} alt="" width={250} />
            </Typography>
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, index) => {
                let name = "t" + index + "el";
                return (
                  <MenuItem
                    key={page}
                    onClick={(e) => handleCloseNavMenu(e, name)}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <img src={Logo} alt="" width={180} />
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {pages.map((page, index) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                name={"t" + index + "el"}
                sx={{
                  my: 2,
                  color: "black",
                  display: "block",
                  fontWeight: "400",
                  textTransform: "unset",
                  fontSize: { md: "1em", lg: "1.2em" },
                }}
              >
                {t(page)}
              </Button>
            ))}
            <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
              <img sx={{ mr: 1 }} src={es} alt="" width={30} />
              <Switch
                onClick={(e) => {
                  handleMangoAwao(e)
                }}
                defaultChecked
                color="default"
              />
              <img sx={{ ml: 1 }} src={en} alt="" width={30} />
            </Box>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            {/* <Tooltip title="Open settings"> */}
            <RoundedButton
              color="#00047a"
              margin={false}
              text={t("Genera tu Colección NFT")}
              to="https://nftgen.devtop.online/"
            />
            {/* </Tooltip> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
