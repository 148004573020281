import React, { lazy, Suspense } from 'react';
import MainBanner from "../Components/HomeComponents/MainBanner"
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// import SecondaryBanner from '../Components/HomeComponents/SecondaryBanner';
// import SolidBanner from "../Components/HomeComponents/SolidBanner"
// import Features from "../Components/HomeComponents/Features"
// import Pricing from "../Components/HomeComponents/Pricing"

const LazySecondayBanner = lazy(() => import('../Components/HomeComponents/SecondaryBanner'))
const LazySolidBanner = lazy(() => import('../Components/HomeComponents/SolidBanner'))
const LazyFeatures = lazy(() => import('../Components/HomeComponents/Features'))
const LazyPricing = lazy(() => import('../Components/HomeComponents/Pricing'))

const Home = () => {

 
    return <>
        <MainBanner />
        <Suspense fallback={null}>
            <LazySecondayBanner />
            <LazySolidBanner />
            <LazyFeatures />
            <LazyPricing />
        </Suspense>
    </>
}

export default Home