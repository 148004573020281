import { Box, Button, Grid, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React, { useEffect, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useTranslation } from "react-i18next"
import CustomTextField from "./CustomTextField"

const CustomForm = () => {
  const { t, i18n } = useTranslation()
  
  const captcha = useRef(null)
  const lenguaje = i18n.language
  const siteKey = '6LfaCV8oAAAAAPqK-lrbuj8D6JnaykOxqqUkVdSK'
  const { enqueueSnackbar } = useSnackbar()
  function onChange(value) {
    captcha.current = value
    console.log("Captcha value:", value)
  }

  const [nombre, setNombre] = useState("")
  const [correo, setCorreo] = useState("")
  const [mensaje, setMensaje] = useState("")

  const [error, setError] = useState(false)

  const validateEmail = (email) => {
    const reg =
      /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)$/
    return reg.test(email)
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    if (nombre === "" || correo === "" || mensaje === "") {
      setError(true)
      enqueueSnackbar("Por favor complete todos los campos", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      })
      return
    }
    if (!validateEmail(correo)) {
      setError(true)
      enqueueSnackbar("Por favor introduzca un correo valido", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      })
      return
    }
    if (captcha.current === null) {
      setError(true)
      enqueueSnackbar("Por favor complete el captcha", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      })
      return
    }

    setError(false)

    const lenguaje = i18n.language

    const objForm = {
      nombre: nombre,
      destinatario: correo,
      mensaje: mensaje,
      trecaptcha: captcha.current,
      lenguaje: lenguaje === "en" ? "en" : "es"
    }

    const headers = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(objForm)
    }
    const res = await fetch(
      "https://nftg-nfanst-api.devtop.online/sendmail",
      headers
    )
      .then((res) => res.json())
      .then((data) => {
        enqueueSnackbar("mensaje enviado", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          }
        })
        setNombre("")
        setCorreo("")
        setMensaje("")
      })
      .catch((error) => {
        console.log(error)
      })

    return res
  }




  console.log()
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      style={{ display: "Block", textAlign: "center", width: "45vw" }}
    >
      <h2 style={{ color: "white" }}>{t("Contacto")}</h2>
      <Grid
        container
        spacing={2}
        sx={{ textAlign: "center", justifyContent: "center" }}
      >
        <Grid item xs={12}>
          <CustomTextField
            id="Nombre"
            label={t("Nombre")}
            variant="outlined"
            fullWidth
            required
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            error={error.error}
            InputLabelProps={{ style: { color: "white" } }}
            inputProps={{
              title: t("CompletarCampos")
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            id="Correo"
            label={t("Correo")}
            variant="outlined"
            fullWidth
            required
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
            error={error.error}
            InputLabelProps={{ style: { color: "white" } }}
            inputProps={{
              title: t("CompletarCampos")
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            id="Mensaje"
            label={t("Mensaje")}
            variant="outlined"
            fullWidth
            required
            multiline
            rows={4}
            value={mensaje}
            onChange={(e) => setMensaje(e.target.value)}
            error={error.error}
            InputLabelProps={{ style: { color: "white" } }}
            inputProps={{
              title: t("CompletarCampos")
            }}
          />
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
           <ReCAPTCHA
            sitekey={siteKey}
            onChange={onChange}
            ref={captcha}
            style={{ padding: "14px" }}
             hl={lenguaje}
             language={lenguaje}
             key={lenguaje}
             translate="yes"
            />
          <Button
            variant="contained"
            type="submit"
            // onClick={() => {
            //   const mensaje = handleSubmit()
            //   console.log(mensaje)
            // }}
            sx={{
              color: "white",
              backgroundColor: "#6b78b8",
              "&:hover": {
                backgroundColor: "white",
                color: "#6b78b8",
                border: "1px solid #6b78b8"
              }
            }}
          >
            {t("Enviar")}
          </Button>
        </div>
      </Grid>
    </Box>
  )
}

export default CustomForm
