import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import Logo from "../Assets/Images/logo.png"
import CustomForm from "./FooterComponents/CustomForm"
import { LegalWarning } from "./FooterComponents/LegalWarning"
import { TermsNCondition } from "./FooterComponents/TermsNCondition"

const Footer = ({signal}) => {
  const [open, setOpen] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const handleOpen2 = () => setOpen2(true)
  const handleClose2 = () => setOpen2(false)


  const { t } = useTranslation()


  return (
    <>
      <Box id="t3el" sx={{ backgroundColor: "#000433" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexWrap: "wrap"
          }}
        >
          <CustomForm signal={signal} />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Stack>
            <Box
              sx={{
                display: "flex",
                flex: 2,
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: { xs: "20%", md: 0 },
                paddingRight: { xs: "20%", md: 0 },
                marginTop: 7
              }}
            >
              <img src={Logo} width="15%" alt="" sx={{ color: "white" }} />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={7}
            >
              <Typography color={"white"} sx={{ p: 1 }} fontSize={18}>
                Copyright © 2023 - Fanaticoins LLC
              </Typography>
              <Typography color={"white"} sx={{ p: 1 }} fontSize={18}>
                /
              </Typography>
              <Typography
                color={"white"}
                sx={{ p: 1, cursor: "pointer" }}
                fontSize={18}
                onClick={handleOpen}
              >
                {t("Aviso Legal")}
              </Typography>
              <Typography color={"white"} sx={{ p: 1 }} fontSize={18}>
                /
              </Typography>
              <Typography onClick={handleOpen2} color={"white"} sx={{ p: 1, cursor: 'pointer' }} fontSize={18}>
               
                  Terms & Conditions
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
      <LegalWarning open={open} handleClose={handleClose} />
      <TermsNCondition open={open2} handleClose={handleClose2} />
    </>
  )
}

export default Footer
